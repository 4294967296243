<template>
    <div class="adManagement-container">
        <el-row>
            <!-- 头部tab-->
            <el-tabs type="border-card" v-model="activeTab"  @tab-click="handleClickTab">
                <el-tab-pane name="customAudience" :lazy="true">
                    <span class="tabTitle"  slot="label">自定义受众</span>
                    <ad-table></ad-table>
                </el-tab-pane>
            </el-tabs>
        </el-row>
    </div>
</template>
<script>
    import AdTable from "@/views/customAudience/components/table/AdTable";
    export default {
        data() {
            return {
                activeTab:'customAudience',
            }
        },
        components: {
            AdTable,
        },
        methods:{
            handleClickTab(v){
                
            }
        }
    }
</script>
<style lang="scss">
    .adManagement-container .el-tabs__content {
        padding: 0;

    }
    .el-table__body-wrapper {
        &::-webkit-scrollbar {
            width: 12px;
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.2);
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
        }
    }
</style>