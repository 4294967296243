<template>
    <div class="shareAudience">
        <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
        >
        <el-form-item label="广告账户" prop="ids">
            <el-input placeholder="请输入广告账户ID，用逗号隔开" v-model="ruleForm.ids"></el-input>
        </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancle">取 消</el-button>
        <el-button type="primary" size="small" @click="creat">保 存</el-button>
        </div>
    </div>
</template>

<script>
import { shareCustomAudience } from "@/api/customAudience.js"
export default {
    data() {
        return {
            ruleForm: {},
            rules:{
                ids:[{required:true,message:'广告账户ID不能为空',trigger:'blur'}]
            }
        };
    },
    props: {
        audience_id: {
            type: String | Number,
            default: ''
        }
    },
    methods: {
        cancle() {
            this.$emit("clear", "shareAudienceShow");
        },
        creat() {
            this.$refs.ruleForm.validate(v=>{
                if(v){
                    this.$showLoading()
                    let adAccountIds = this.ruleForm.ids.replace(/，/g, ',').split(',')
                    let request = {
                        customAudienceId: this.audience_id,
                        adAccountIds: adAccountIds
                    }
                    shareCustomAudience(request).then(res => {
                        this.$hideLoading()
                        if (res.code === 0) {
                            this.$message({
                                type:'success',
                                message:'更新成功'
                            })
                            this.$emit('getList');
                            this.$emit("clear")
                        }
                    })
                }
            })
        }
    }
};
</script>

<style lang="scss">
.shareAudience {
    .dialog-footer {
        text-align: right;
    }
    .el-select {
        width: 600px;
    }
}
</style>