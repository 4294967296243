<template>
  <div class="gui-ze">
    <el-form :model="ruleForm" ref="ruleForm" :rules="rulesForm">
      <el-row>
        <el-col :span="6" style="height:100vh;" v-if="detailsData.id?false:true">
          <div class="instructions">
            <div class="content-left">
              <div class="content-left-inner">
                <div class="sc-hrjYtz jTAUQi">
                  <div class="content_right_title vertical_center flex_start_v">
                    <img
                      src="@/assets/icon/materials.svg"
                      alt="icon"
                      style="width: 18px; height: 18px; margin-right: 6.5px"
                    />
                    <h5 style="font-size: 14px">如何创建网站访客自定义受众</h5>
                  </div>
                </div>
                <div style="margin-top: 10px; margin-bottom: 10px">
                  可采用以下两种方法：
                </div>
                <div style="margin-bottom: 10px">
                  选择<b>任意</b>，即可在自定义受众中包含或排除在特定时间段内符合你设置的任一条件的用户。例如，你可以包含在过去
                  7 天内访问过你网站的用户<b>或</b>在过去 14
                  天内将商品加入心愿单的用户。
                </div>
                <div style="margin-bottom: 10px">
                  选择<b>所有</b>，即可在自定义受众中包含或排除在特定时间段内符合你设置的所有条件的用户。例如，你可排除同时符合以下条件的用户：过去
                  14 天内在网站中搜索过商品<b>且</b>过去 180
                  天内曾在网站中购物。
                </div>
                <div>
                  此外，你还可以添加规则来限制花费时间、操作频率、使用的设备、访问的网址等参数。
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="detailsData.id?24:18">
          <div class="self_content">
            <div class="content-right">
              <div class="title">把用户加入你的受众</div>
              <div class="self_select">
                <el-form-item prop="operator">
                  <span>包含符合以下</span>
                  <el-select
                    v-model="ruleForm.operator"
                    size="small"
                    placeholder="请选择"
                    :disabled="detailsData.id?true:false"
                  >
                    <el-option
                      v-for="item in optionsSelect"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <span>条件的用户</span>
                </el-form-item>
              </div>
              <el-form-item class="self_rules">
                <div v-for="(domain, index) in inclusionsRules" :key="index">
                  <div data-test="logic-text" class="sc-jTycuM fkbmUO" v-if="index>0">{{ruleForm.operator=='or'?'或':'和'}}<span @click="f_delAudience('add',index)" v-if="detailsData.id?false:true">X</span></div>
                  <unitCatagrory
                    v-model="inclusionsRules[index]"
                    :key="detailsData.id+'2'"
                    :id="detailsData.id||''"
                    @input="f_change"
                  ></unitCatagrory>
                </div>
              </el-form-item>
              <div class="exclude-panel-title" v-if="exclusionsRules.length>0"><b>排除</b>符合以下<b>任意</b>条件的用户</div>
              <el-form-item class="self_rules self_rules_exclude" v-if="exclusionsRules.length>0">
                <div v-for="(domain, index) in exclusionsRules" :key="index">
                  <div class="remove_unit">
                    <div data-test="logic-text" class="sc-jTycuM fkbmUO" v-if="index>0">或</div>
                    <span @click="f_delAudience('del',index)" v-if="detailsData.id?false:true">X</span>
                  </div>
                  <unitCatagrory
                    :id="detailsData.id||''"
                    :key="detailsData.id+'1'"
                    v-model="exclusionsRules[index]"
                    @input="f_change"
                  ></unitCatagrory>
                </div>
              </el-form-item>
              <div class="panel_operation_wrap" v-if="sumRulesNum < 5&&(detailsData.id?false:true)">
                <div class="panel_operation_item panel_operation_item_add" @click="f_addAudience('add')">
                  <span>+</span>{{ruleForm.operator=='or'?'添加更多用户':'进一步缩小范围'}}
                </div>
                <div class="panel_operation_item panel_operation_item_remove" @click="f_addAudience('del')">
                  <span>-</span>排除用户
                </div>
              </div>
              <div class="title title-1" :style="{marginTop:detailsData.id?'15px':'0'}">为受众命名</div>
              <el-form-item prop="name" class="self_rules_name">
                <el-input
                  :disabled="detailsData.id?true:false"
                  v-model="ruleForm.name"
                  placeholder="命名受众"
                  :maxlength="50"
                ></el-input>
                <span class="description_btn" @click="isDescribe = false" v-if="detailsData.id?false:true">{{
                  isDescribe ? "添加描述" : ""
                }}</span>
              </el-form-item>
              <el-form-item
                prop="description"
                class="self_rules_name"
                label="描述"
                v-if="!isDescribe"
              >
                <el-input
                  v-model="ruleForm.description"
                  type="textarea"
                  :maxlength="250"
                  show-word-limit
                  :disabled="detailsData.id?true:false"
                  :rows="3"
                ></el-input>
                <span class="description_btn" @click="f_hiddenDescribe" v-if="detailsData.id?false:true">{{
                  isDescribe ? "" : "隐藏描述"
                }}</span>
              </el-form-item>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" v-if="detailsData.id?false:true">
      <el-button size="small" @click="f_close">取 消</el-button>
      <el-button type="primary" size="small" @click="f_sure">创 建</el-button>
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
import unitCatagrory from "../components/guize/unitCatagrory.vue";
import * as API from '@/api/customAudience.js'
export default {
  components: { unitCatagrory },
  props: {
    accountId:{
      type:String,
      default:''
    },
    detailsData:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      rulesForm:{
        name: [
          { required: true, message: '请为受众命名', trigger: ['change','blur'] }
        ],
      },
      ruleForm: {
        description:'',
        name:'',
        operator: "or",
        rule: {
          // inclusions: { operator: "or", rules: [] },
          // exclusions: { operator: "or", rules: [] },
        },
        // accountId:"683525339085422",
      },
      isDescribe: true,
      optionsSelect: [
        { value: "or", label: "任意" },
        { value: "and", label: "所有" },
      ],
      // 最小单元格式
      unitBox: {
        aggregation: {},
        event_sources: [{
          id: 3260379027391808,
          type: "pixel",
          name: "河南省顺风服饰有限公司的 Pixel 像素代码",
        }],
        template: "ALL_VISITORS",
        retention_seconds: 30 * 24 * 3600,
        filter: {
          operator: "and",
          filters: [
            { field: "url", operator: "i_contains", value: "" },
            // {
            //   filters: [
            //     // {field: "device_type", operator: "eq", value: "desktop"},
            //     {
            //       field: "device_type",
            //       operator: "eq",
            //       value: "mobile_android_phone",
            //     },
            //     {
            //       field: "device_type",
            //       operator: "eq",
            //       value: "mobile_android_tablet",
            //     },
            //     {
            //       field: "device_type",
            //       operator: "eq",
            //       value: "mobile_ipad",
            //     },
            //     {
            //       field: "device_type",
            //       operator: "eq",
            //       value: "mobile_iphone",
            //     },
            //     {
            //       field: "device_type",
            //       operator: "eq",
            //       value: "mobile_ipod",
            //     },
            //     {
            //       field: "device_type",
            //       operator: "eq",
            //       value: "mobile_phone",
            //     },
            //     {
            //       field: "device_type",
            //       operator: "eq",
            //       value: "mobile_tablet",
            //     },
            //     {
            //       field: "device_type",
            //       operator: "eq",
            //       value: "mobile_windows_phone",
            //     },
            //   ],
            //   operator: "or",
            // },
            // {
            //   operator: "or",
            //   filters: [{field: "url", operator: "i_contains", value: ""}]
            // },
            // {
            //   operator: "or",
            //   filters: [{field: "url", operator: "i_contains", value: "11111"},
            //     {field: "url", operator: "i_contains", value: "22222"},
            //     {field: "url", operator: "i_contains", value: "3333"}],
            // },
            // {
            //   operator: "or",
            //   filters: [{field: "url", operator: "i_contains", value: "4444"},
            //     {field: "url", operator: "i_contains", value: "55555"},
            //     {field: "url", operator: "i_contains", value: "66666"}],
            // },
          ],
        },
      },
      // 包含的规则
      inclusionsRules: [],
      // 排除的规则
      exclusionsRules: [],
      pixelOptions: []
    };
  },
  computed: {
    // 总规则不超过5个
    sumRulesNum() {
      return this.inclusionsRules.length + this.exclusionsRules.length;
    },
  },
  created() {},
  mounted() {
    if(!this.detailsData.id) this.inclusionsRules.push(this.unitBox)
    else  this.f_initEdit()
  },
  beforeDestroy() {},
  methods: {
    // 编辑的时候或者详情的时候
    f_initEdit(){
        let data = _.cloneDeep(this.detailsData)
        let {name,description,rule} = {...data}
        let operator = JSON.parse(rule).inclusions.operator
        this.ruleForm = {name,description,operator}
        rule = JSON.parse(rule)
        this.inclusionsRules = rule?.inclusions?.rules || []
        this.exclusionsRules = rule?.exclusions?.rules || []
        if(description) this.isDescribe = false
        console.log(this.inclusionsRules,this.exclusionsRules,"初始化")
    }, 
    f_change(val) {
      console.log(val, "这是最后的大表单",this.inclusionsRules,this.exclusionsRules);
    },
    // 确定
    f_sure(){
      // console.log(this.ruleForm,"提交了====")
      // this.$bus.emit("onCheck")
      this.$refs['ruleForm'].validate((valid) => {
          if (!valid) {
            return 
          }
          if(this.exclusionsRules.length>0) {
            let exclusions =  {operator:this.ruleForm.operator,rules:this.exclusionsRules} 
            console.log(exclusions, 'exclusions')    
            // this.$set(this.rulesForm.rule,'exclusions',exclusions)
            this.ruleForm.rule.exclusions = exclusions
          }
          if(this.inclusionsRules.length>0) {
            let inclusions = {operator:this.ruleForm.operator,rules:this.inclusionsRules}
            let rules = inclusions.rules
            let newRules = rules.map(v => {
              let obj = {...v}
              let event_sources = obj.event_sources.map(_v => {
                let _vRules = {
                  id: _v.id,
                  type: 'pixel'
                }
                return _vRules
              })
              obj.event_sources = event_sources
              return obj
            })
            inclusions.rules = newRules
            // this.$set(this.rulesForm.rule,'inclusions',inclusions)
            this.ruleForm.rule.inclusions = inclusions
          }
          // this.$set(this.ruleForm,'accountId',this.$store.getters.accountId)
          // this.$set(this.ruleForm,'rule',JSON.stringify(this.ruleForm.rule))
          this.$showLoading();
          API.createCustomaudience({accountId:this.accountId,audience:JSON.stringify(this.ruleForm)}).then(res=>{
            // console.log(res,"接口数据=====")
            this.$hideLoading();
            if(res.code == 0){
              this.$message({
                  title: "提示",
                  message: '创建成功',
                  type: "success",
              });
              this.$emit('clear')
              this.$emit('back')
            }
          })
        });
    },
    // 取消
    f_close(){
      this.$emit('clear')
    },
    // 改变描述状态
    f_hiddenDescribe() {
      this.isDescribe = true;
      this.ruleForm.description = "";
    },
    // 添加更多受众
    f_addAudience(val){
      if(val=='add'&&this.sumRulesNum<5) this.inclusionsRules.push(this.unitBox)
      if(val=='del'&&this.sumRulesNum<5) this.exclusionsRules.push(this.unitBox)
    },
    // 删除受众
    f_delAudience(val,index){
      if(val=='add'&&this.inclusionsRules.length>0) this.inclusionsRules.splice(index,1)
      if(val=='del'&&this.exclusionsRules.length>0) this.exclusionsRules.splice(index,1)
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 7px; /*滚动条宽度*/
	height: 7px; /*滚动条高度*/
}

::-webkit-scrollbar-track {
	border-radius: 3px; /*滚动条的背景区域的圆角*/
	background-color: #f2f2f2; /*滚动条的背景颜色*/
}

::-webkit-scrollbar-thumb {
	background: #CCCCCC !important;
	border-radius: 10px !important;
}
.gui-ze {
  max-height: 650px;
  overflow: hidden;
  .instructions {
    height: 100%;
    .content-left {
      font-feature-settings: "tnum", "tnum";
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      height: 100%;
      position: relative;
      transition: all 0.3s linear 0s;
      padding: 40px 20px 30px;
      font-weight: 400;
      background: rgb(245, 245, 247);
      .content-left-inner {
        border: 1px dashed rgb(185, 187, 190);
        border-radius: 4px;
        padding: 20px;
        font-size: 12px;
        b {
          color: rgba(0, 0, 0, 0.85);
        }
        .content_right_title {
          margin-top: -30px;
          background: rgb(245, 245, 247);
          padding: 5px;
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }
  .self_content {
    .content-right {
      max-height: 600px;
      overflow: auto;
      flex: 1 1 0%;
      padding: 40px 20px 10px 40px;
      transition: all 0.3s linear 0s;
      background: white;
      margin-left: 15px;
      .title {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 15px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        &::before {
          content: "1";
          position: absolute;
          margin-left: -35px;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          border: 2px solid rgb(30, 144, 247);
          color: rgb(30, 144, 247);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 50%;
        }
      }
      .title-1 {
        &::before {
          content: "2";
        }
      }
      .self_select {
        ::v-deep.el-form-item {
          margin-bottom: 10px;
          .el-select,
          .el-input {
            width: 100px;
            margin-left: 5px;
            margin-right: 15px;
          }
        }
      }
      .exclude-panel-title{
        font-size: 13px; 
        font-weight: 400; 
        margin-bottom: 10px;
        margin-top: 10px;
        b{
          color: rgba(0, 0, 0, 0.85);
        }
      }
      ::v-deep.self_rules {
        margin-left: -10px !important;
        margin-bottom: 0;
        .fkbmUO {
            cursor: pointer;
            margin-left: 15px;
            color: rgb(64, 169, 255);
            font-size: 12px;
            font-weight: bold;
            position: relative;
            span{
              position: absolute;
              right: 10px;
              top: 40px;
              color: #606266;
              font-size: 14px;
              font-weight: normal;
              cursor: pointer;
              z-index: 1;
            }
        }
        .remove_unit{
          position: relative;
          span{
            position: absolute;
            right: 10px;
            cursor: pointer;
            z-index: 1;
          }
        }
      }
      ::v-deep.self_rules_exclude{
        margin-left: 10px !important;
      }
      ::v-deep.self_rules_name {
        .el-form-item__content {
          display: flex;
          justify-content: space-between;
        }
        .el-form-item__label {
          float: none;
        }
        .description_btn {
          color: rgb(64, 169, 255);
          height: 38px;
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: end;
          justify-content: flex-end;
          display: inline-block;
          width: 100px;
          text-align: right;
        }
      }
    }
    .panel_operation_wrap {
      display: flex;
      -webkit-box-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      align-items: center;
      margin-right: 20px;
      .panel_operation_item {
        cursor: pointer;
        padding-left: 20px;
        color: rgb(64, 169, 255);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        transition: all 0.3s linear 0s;
        margin-top: 10px;
        span {
          display: flex;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: rgb(64, 169, 255);
          color: white;
          font-size: 12px;
          font-weight: bold;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          margin-right: 5px;
        }
      }
    }
  }
  .dialog-footer{
    position: absolute;
    bottom: -10px;
    height: 55px;
    width: 1000px;
    background: #fff;
  }
}
</style>